(function ($, window) {
    'use strict';
    
    /* SUPPORTS FIXED POSITION?
    *
    * Based on code from jQuery Mobile 1.1.0
    * http://jquerymobile.com/
    *
    * In a nutshell, we need to figure out if fixed positioning is supported.
    * Unfortunately, this is very difficult to do on iOS, and usually involves
    * injecting content, scrolling the page, etc.. It's ugly.
    * jQuery Mobile uses this workaround. It's not ideal, but works.
    *
    * Modified to detect IE6
    * ========================= */
    
    var supportsFixedPosition = (function () {
        var ua = navigator.userAgent,
            platform = navigator.platform,
            // Rendering engine is Webkit, and capture major version
            wkmatch = ua.match(/AppleWebKit\/([0-9]+)/),
            wkversion = !!wkmatch && wkmatch[1],
            ffmatch = ua.match(/Fennec\/([0-9]+)/),
            ffversion = !!ffmatch && ffmatch[1],
            operammobilematch = ua.match(/Opera Mobi\/([0-9]+)/),
            omversion = !!operammobilematch && operammobilematch[1],
            iematch = ua.match(/MSIE ([0-9]+)/),
            ieversion = !!iematch && iematch[1];
        
        return !(
            // iOS 4.3 and older : Platform is iPhone/Pad/Touch and Webkit version is less than 534 (ios5)
            ((platform.indexOf("iPhone") > -1 || platform.indexOf("iPad") > -1  || platform.indexOf("iPod") > -1) && wkversion && wkversion < 534) ||
            
            // Opera Mini
            (window.operamini && ({}).toString.call(window.operamini) === "[object OperaMini]") ||
            (operammobilematch && omversion < 7458) ||
            
            //Android lte 2.1: Platform is Android and Webkit version is less than 533 (Android 2.2)
            (ua.indexOf("Android") > -1 && wkversion && wkversion < 533) ||
            
            // Firefox Mobile before 6.0 -
            (ffversion && ffversion < 6) ||
            
            // WebOS less than 3
            ("palmGetResource" in window && wkversion && wkversion < 534) ||
            
            // MeeGo
            (ua.indexOf("MeeGo") > -1 && ua.indexOf("NokiaBrowser/8.5.0") > -1) ||
            
            // IE6
            (ieversion && ieversion <= 6)
        );
    }());
    
    /* STYLES
    * 
    * Baked-in styles that we'll apply to our elements.
    * In an effort to keep the plugin simple, these are not exposed as options.
    * That said, anyone can override these in their own stylesheet.
    * ========================= */
    var styles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            width: '100%',
            height: '100%'
        },
        wrap: {
            left: 0,
            top: 0,
            overflow: 'hidden',
            margin: 0,
            padding: 0,
            height: '100%',
            width: '100%',
            zIndex: -999999
        },
        vid: {
            position: 'absolute',
            display: 'none',
            margin: 0,
            padding: 0,
            border: 'none',
            width: 'auto',
            height: 'auto',
            maxHeight: 'none',
            maxWidth: 'none',
            zIndex: -999999
        },
        img: {
            position: 'absolute',
            display: 'none',
            margin: 0,
            padding: 0,
            border: 'none',
            width: 'auto',
            height: 'auto',
            maxHeight: 'none',
            maxWidth: 'none',
            zIndex: -999999
        },
        controls: {
            backgroundColor : 'transparent',
            border : 0,
            bottom : '10px',
            color : 'rgb(255,255,255)',
            margin : 0,
            padding : 0,
            position : 'absolute',
            right : '10px'
        },
        watermark: {
            position : 'absolute',
            bottom : '10px',
            left : '10px'
        }
    },
    
        /* CLASS DEFINITION
        * ========================= */
        Poosh = function (container, source, options) {
            this.options = $.extend({}, $.fn.poosh.defaults, options || {});
            // Convenience reference to know if the container is body.
            this.isBody = container === document.body;
            /* We're keeping track of a few different elements
            *
            * Container: the element that Poosh was called on.
            * Wrap: a DIV that we place the video or image into, so we can hide the overflow.
            * Root: Convenience reference to help calculate the correct height.
            */
            this.$container = $(container);
            this.$root = this.isBody ? supportsFixedPosition ? $(window) : $(document) : this.$container;
            // Don't create a new wrap if one already exists (from a previous instance of Poosh)
            var $existingWrap = this.$container.children(".poosh").first();
            this.$wrap = $existingWrap.length ? $existingWrap : $('<div class="poosh"></div>').css(styles.wrap).appendTo(this.$container);
            // Non-body elements need some style adjustments
            if (!this.isBody) {
                // If the container is statically positioned, we need to make it relative,
                // and if no zIndex is defined, we should set it to zero.
                var position = this.$container.css('position'),
                    zIndex = this.$container.css('zIndex');
                this.$container.css({
                    position: position === 'static' ? 'relative' : position,
                    zIndex: zIndex === 'auto' ? 0 : zIndex,
                    background: 'none'
                });
                // Needs a higher z-index
                this.$wrap.css({zIndex: -999998});
            }
            // Fixed or absolute positioning?
            this.$wrap.css({
                position: this.isBody && supportsFixedPosition ? 'fixed' : 'absolute'
            });
            this.show(source);
            // Listen for resize
            $(window)
                .on('resize.poosh', $.proxy(this.resize, this))
                .on('orientationchange.poosh', $.proxy(function () {
                    // Need to do this in order to get the right window height
                    if (this.isBody && window.pageYOffset === 0) {
                        window.scrollTo(0, 1);
                        this.resize();
                    }
                }, this));
        };
    
    /* PLUGIN DEFINITION
    * ========================= */
    $.fn.poosh = function (source, options) {
        // We need at least one video or method name
        if (source === undefined) {
            $.error("No source was supplied for Poosh");
        }
        
        /*
        * Scroll the page one pixel to get the right window height on iOS
        * Pretty harmless for everyone else
        */
        if ($(window).scrollTop() === 0) {
            window.scrollTo(0, 0);
        }
        
        return this.each(function () {
            var $this = $(this),
                obj = $this.data('poosh');
            // Do we already have an instance attached to this element?
            if (obj) {
                // Is this a method they're trying to execute?
                if (typeof source === 'string' && typeof obj[source] === 'function') {
                    // Call the method
                    obj[source](options);
                    // No need to do anything further
                    return;
                }
                // Merge the old options with the new
                options = $.extend(obj.options, options);
                // Remove the old instance
                obj.destroy(true);
            }
            obj = new Poosh(this, source, options);
            $this.data('poosh', obj);
        });
    };
    
    // If no element is supplied, we'll attach to body
    $.poosh = function (source, options) {
        // Return the instance
        return $('body').poosh(source, options).data('poosh');
    };
    
    // Custom selector
    $.expr[':'].poosh = function (elem) {
        return $(elem).data('poosh') !== undefined;
    };
    
    /* DEFAULTS
    * ========================= */
    
    $.fn.poosh.defaults = {
        cover: 'https://farm6.staticflickr.com/5611/15486715559_e9aaa87c2c_h.jpg',  // Cover image
        centeredX: true,                                                            // Should we center the image on the X axis?
        centeredY: true,                                                            // Should we center the image on the Y axis?
        fade: 0,                                                                    // Speed of fade transition between slides
        controls: false,                                                            // Show controls
        watermark: false                                                            // Show watermark
    };
    
    /* PUBLIC METHODS
    * ========================= */
    Poosh.prototype = {
        resize: function () {
            
            function resizeBackground(element, bgType, bgRatio) {
                var bgCSS = {left: 0, top: 0},
                    rootWidth = element.isBody ? element.$root.width() : element.$root.innerWidth(),
                    bgWidth = rootWidth,
                    rootHeight = element.isBody ? (window.innerHeight ? window.innerHeight : element.$root.height()) : element.$root.innerHeight(),
                    bgHeight = bgWidth / bgRatio,
                    bgOffset;

                // Make adjustments based on source ratio
                if (bgHeight >= rootHeight) {
                    bgOffset = (bgHeight - rootHeight) / 2;
                    if (element.options.centeredY) {
                        bgCSS.top = '-' + bgOffset + 'px';
                    }
                } else {
                    bgHeight = rootHeight;
                    bgWidth = bgHeight * bgRatio;
                    bgOffset = (bgWidth - rootWidth) / 2;
                    if (element.options.centeredX) {
                        bgCSS.left = '-' + bgOffset + 'px';
                    }
                }

                element.$wrap.css({width: rootWidth, height: rootHeight}).find(bgType).css({width: bgWidth, height: bgHeight}).css(bgCSS);
            }
            
            if (!Modernizr.video || Modernizr.touch) {
                resizeBackground(this, 'img', this.$img.data('imageRatio'));
            } else {
                resizeBackground(this, 'video', this.$vid.data('videoRatio'));
            }
            
            return this;
        },
        pause: function () {
            var $vid = this.$wrap.find("video").get(0);
            $vid.stop();
        },
        // Show the slide at a certain position
        show: function (source) {
            // Vars
            var self = this,
                sources = [];
            
            function addOverlay() {
                var $existingOverlay = self.$wrap.children(".poosh-overlay").first();
                self.$overlay = $existingOverlay.length ? $existingOverlay : $('<div class="poosh-overlay"></div>').css(styles.overlay).appendTo(self.$wrap);
            }
            
            function addControls() {
                
                function pauseOrPlay() {
                    var $vid = self.$wrap.find("video").get(0);
                    
                    if ($vid.paused === true) {
                        $vid.play();
                        self.$controls.html('<span class="fa fa-pause"></span> Pause');
                    } else {
                        $vid.pause();
                        self.$controls.html('<span class="fa fa-play"></span> Play');
                    }
                }
                
                var $existingControls = self.$wrap.children(".poosh-controls").first();
                
                self.$controls = $existingControls.length ? $existingControls : $('<button type="button" class="poosh-controls"></button>').html('<span class="fa fa-pause"></span> Pause').css(styles.controls).click(pauseOrPlay).appendTo(self.$wrap);
            }
            
            function addWatermark() {
                // var $existingWatermark = self.$wrap.children(".poosh-watermark").first();
                // self.$watermark = $existingWatermark.length ? $existingWatermark : $('<img class="poosh-watermark">').css(styles.watermark).attr("src", "/img/poosh.png").attr("alt", "poosh").appendTo(self.$wrap);
            }
            
            if (!Modernizr.video || Modernizr.touch) {
                self.$img = $("<img />")
                    .css(styles.img)
                    .prop('src', self.options.cover)
                    .bind('load', function (e) {
                        // Save the ratio
                        $(this).data('imageRatio', this.width / this.height);
                        $(window).resize(function () {
                            self.resize();
                        }).trigger("resize");
                        if (self.options.fade > 0) {
                            $(this).fadeIn(self.options.fade);
                        } else {
                            $(this).show();
                        }
                    })
                    .appendTo(self.$wrap);
            } else {
                $.each(source, function (key, value) {
                    sources.push($("<source />", {src: value.src, type: value.type}));
                });
                self.$vid = $('<video />')
                    .css(styles.vid)
                    .prop('autoplay', false)
                    .prop('loop', true)
                    .prop('preload', 'auto')
                    .prop('muted', true)
                    .append(sources)
                    .bind('loadedmetadata', function (e) {
                        // Save the ratio
                        $(this).data('videoRatio', this.videoWidth / this.videoHeight);
                        $(window).resize(function () {
                            self.resize();
                        }).trigger("resize");
                    })
                    .bind("canplaythrough", function () {
                        $(this).trigger("play");
                        if (self.options.overlay) {
                            addOverlay();
                        }
                        if (self.options.controls) {
                            addControls();
                        }
                        if (self.options.watermark) {
                            addWatermark();
                        }
                        if (!$(this).is(":visible")) {
                            if (self.options.fade > 0) {
                                $(this).fadeIn(self.options.fade);
                            } else {
                                $(this).show();
                            }
                        }
                    })
                    .appendTo(self.$wrap);
            }
            
            self.resize();
            
            return self;
        },
        destroy: function (preserveBackground) {
            // Stop the resize events
            $(window).off('resize.poosh orientationchange.poosh');
            
            // Clear the interval
            clearInterval(this.interval);
            
            // Remove Poosh
            if (!preserveBackground) {
                var $vid = this.$wrap.find("video").get(0),
                    $img = this.$wrap.find("img").get(0),
                    $overlay = this.$wrap.find(".poosh-overlay").get(0),
                    $watermark = this.$wrap.find(".poosh-watermark").get(0);
                $vid.pause();
                $vid.src = "";
                $vid.load();
                $vid.remove();
                $img.remove();
                $overlay.remove();
                $watermark.remove();
                this.$wrap.remove();
            }
            this.$container.removeData('poosh');
        }
    };
}(jQuery, window));